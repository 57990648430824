
const staticPatam = ['name', 'address1', 'state_code', 'postal_code', 'country_code']
export default {
    emits: ['update:visible'],
    props: {
        // 弹窗是否打开
        visible: Boolean,
        // 当前用户数据
        data: Object
    },
    data() {
        return {
            groupData: [],
            // 数据加载状态
            dataLoading: false,
            // 提交状态
            loading: false,
            // 展开的keys
            expandKeys: [],
            // 选中的keys
            checkedKeys: [],
            // 查询值
            searchText: ""
        };
    },
    watch: {
        visible() {
            if (this.visible) {
                this.query();
            }
        }
    },
    computed: {
        userData() {
            return this.groupData.filter(i =>
                staticPatam.some(key =>
                    // 条件
                    i[key].toLocaleLowerCase().includes(this.searchText.toLocaleLowerCase())
                )
            )
        }
    },
    methods: {
        query() {
            this.groupData = [];
            this.expandKeys = [];
            this.checkedKeys = [];

            if (!this.data) {
                return;
            }

            this.dataLoading = true;

            const postMethods = new Promise((resovle, reject) => {
                this.$http.get(`/admin/shipper/list`, {
                    params: {
                        limit: 999,
                        page: 1
                    }
                }).then(res => {
                    if (res.data.code === 0) {
                        let eks = [],
                            cks = [];
                        res.data.data.forEach(d => {
                            d.key = d.id;
                            d.title = `${d.name} - ${d.address1} - ${d.state_code} ${d.postal_code}, ${d.country_code}`
                            eks.push(d.key);
                        });
                        this.groupData = this.$util.toTreeData(res.data.data, 'id');
                        this.$util.eachTreeData(this.groupData, (d) => d.checked && cks.push(d.key));
                        this.expandKeys = eks;
                        this.checkedKeys = cks;
                        resovle();
                    } else {
                        reject(res.data.msg);
                    }
                }).catch(e => {
                    reject(e);
                })
            });

            Promise.all([postMethods, this.getCheckKeys()])
                .then((res) => {
                    this.checkedKeys = res[1].checked;
                })
                .catch(e => {
                    this.$message.error(e.message);
                })
                .finally(() => {
                    this.dataLoading = false;
                })

        },
        save() {
            this.loading = true;
            let ids = [];
            this.$util.eachTreeData(this.groupData, (d) => {
                if (this.checkedKeys.some(c => c === d.key)) {
                    ids.push(d.key);
                }
            });
            this.$http.post(`/admin/ship_method/update_binding/${this.data.id}`, {
                ids: this.checkedKeys
            }).then(res => {
                this.loading = false;
                if (res.data.code === 0) {
                    this.$message.success(res.data.msg);
                    this.updateVisible(false);
                } else {
                    this.$message.error(res.data.msg);
                }
            }).catch(e => {
                this.loading = false;
                this.$message.error(e.message);
            });
        },
        /* 更新visible */
        updateVisible(value) {
            this.$emit('update:visible', value);
        },
        /* 处理复选框 */
        handleCheck(checkedKeys) {
            if (checkedKeys.length > 1) {
                this.checkedKeys.shift();
            }
        },
        /* 获取选项 */
        getCheckKeys() {
            return new Promise((resolve, reject) => {
                this.$http.get("/admin/ship_method/list_binding").then(res => {
                    if (res.data.code == 0) {
                        let arrObject = {
                            checked: [],
                            unChecked: []
                        };

                        res.data.data.forEach(d => {
                            if (d.ship_method_id == this.data.id) arrObject['checked'].push(d.shipper_id);
                            else arrObject['unChecked'].push(d.shipper_id)
                        })

                        resolve(arrObject);
                    } else {
                        reject(res.data.msg)
                    }
                }).catch(e => {
                    reject(e)
                })
            })
        },
        handleSelect(keys) {
            this.checkedKeys = keys;
            this.handleCheck(keys);
        }
    }
}
